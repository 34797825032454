import React from "react"
import { Link } from "gatsby"

import Icon from "../images/fourzerofour.inline.svg"
import PayrocBrand from "../components/page/payroc-brand"
import PageMetadata from "../components/page/page-metadata"
import { HeadingOne, Paragraph } from "../components/common/typography"

const NotFoundPage: React.FC = () => {
  return (
    <>
      <PageMetadata title="Page not found" />
      <div className="justify-center bg-split-white-sky-blue dark:bg-split-dark-navy-light-navy text-center">
        <Icon alt="Page not found" className="m-auto mt-14 mb-9" />

        <HeadingOne unanchored>Page not found</HeadingOne>
        <Paragraph>It looks like nothing was found, it may have been removed or now lives at a different location.</Paragraph>

        <div className="text-center mt-9">
          <Link to="/" className="bg-tangerine hover:bg-dark-tangerine py-3 px-8 text-white rounded-md font-rocgrotesk">
            Return to homepage
          </Link>
        </div>

        <PayrocBrand className="text-midnight-sky dark:text-sky-blue w-24 m-auto my-60" />
      </div>
    </>
  )
}

export default NotFoundPage
