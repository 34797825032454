import React from "react"
import { Link } from "gatsby"

type Props = {
  className: string
}

const PayrocBrand: React.FC<Props> = ({ className }) => (
  <Link to="/" aria-label="Visit docs.payroc.com">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 515.95 95.58" fill="currentColor" className={className}>
      <path d="M19.16,59.72V95.58H0V2.72H19.16v11C24.73,6.18,34.87.12,48.84.12c21.64,0,36.72,14.35,36.72,36.6s-15.08,36.6-36.72,36.6C34.87,73.32,24.73,67.26,19.16,59.72ZM20.33,27.2c-1.52,4.51-1.52,14.53,0,19C23.47,55.52,34,57.37,44,57.37c14.83,0,23.24-7.29,23.24-20.65S58.85,16.07,44,16.07C34,16.07,23.47,17.92,20.33,27.2Z" />
      <path d="M175.69,32.77V57.86a25.22,25.22,0,0,0,3.1,13H159.13a16.85,16.85,0,0,1-2.48-8v-.12c-5.19,5.68-15.2,10.63-28.31,10.63-14.59,0-33.51-5.81-33.51-23.49s18.92-23.37,33.51-23.37c13.11,0,23.12,4.82,28.31,10.63V32.64c0-12.61-8.28-18-24.6-18-9.27,0-16.69,1.24-24.85,5.69L101.51,7.67c8.9-4.58,22.25-7.55,34.37-7.55C160.73.12,175.69,11.62,175.69,32.77ZM157,49c-5.07-8-15.2-10-24-10-7.92,0-19.54,1.85-19.54,10.88s11.62,11,19.54,11c8.78,0,18.91-2.1,24-10.14Z" />
      <path className="cls-1" d="M270.53,2.72,220.82,95.58H201.29l16.19-28.94L182.87,2.72H203l24.23,46.12L250.74,2.72Z" />
      <path d="M281.41,70.85V2.72h19.16l-.7,16.88c5.57-10.26,18-18.36,31.12-18.36V17.68c-14.84,0-26.34,5.81-30.42,17.81V70.85Z" />
      <path d="M336.55,36.85C336.55,14.1,353.12.12,380.07.12s43.52,14,43.52,36.73S406.9,73.57,380.07,73.57,336.55,59.47,336.55,36.85Zm68.62-2.1c0-14.22-10.63-19-25.1-19s-25,8-25,22.19,10.63,20.1,25,20.1S405.17,48.85,405.17,34.75Z" />
      <path d="M477.62,73.57c-26.7,0-43.15-14.22-43.15-36.72S450.92,0,477.62,0c19.42,0,32.28,8.41,38,18.79L497.9,25.72c-4-7.54-11.5-10.26-20.15-10.26-14.22,0-29.26,7-29.26,21.39,0,14.09,15,21.26,29.26,21.26,8.65,0,16.44-2.84,20.65-10.63L516,54.53C510.27,65.16,497.28,73.57,477.62,73.57Z" />
    </svg>
  </Link>
)

export default PayrocBrand
